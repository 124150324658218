import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Box from "../../../../../components/box"
import Note from "../../../../../components/note"
import InlineHelp from "../../../../../components/inline-help"
import SyllablesTask from "../../../../../components/syllables-task"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "haelfte-des-lebens",
          chapterId: "01-zwei-haelften",
          taskId: "silben-klopfen",
        })
        navigate(
          "/kurse/haelfte-des-lebens/01-zwei-haelften/silben-klopfen/loesung"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Silben klopfen" />
      <Box>
        <Stack>
          <Heading as="h1" level={2}>
            Silben klopfen
          </Heading>
          <Paragraph>
            Lässt sich das Spiel mit den Zahlen auch auf der Ebene der Silben
            beobachten? Sehen wir uns die Anzahl der Silben in den Versen einmal
            genauer an.
          </Paragraph>
          <Stack space={6}>
            <Note variant="task">
              <>
                Zähle die Silben der Zeilen. Hölderlin hat seine Silben beim
                Schreiben direkt auf den Tisch geklopft. Das kannst du auch
                machen, indem du die Worte laut vor dich hinsagst und bei jeder
                Silbe den{" "}
                <span
                  role="img"
                  aria-label="Auf den Tisch klopfende Faust nach links"
                >
                  🤛
                </span>
                -Button drückst.
              </>
            </Note>
            <InlineHelp title="Wie erkenne ich eine Silbe?">
              Manche Wörter bestehen nur aus einer Silbe (z.B. Eis oder Tee).
              Manche Wörter bestehen aber auch aus mehreren Silben (z.B. Ku-chen
              oder Mar-me-la-de). Du erkennst sie daran, dass du zwischen den
              Silben beim Sprechen eine Pause machen kannst. Bei den meisten
              Wörtern beginnt eine neue Silbe mit einem Konsonanten.
            </InlineHelp>
          </Stack>
          <Stack>
            <SyllablesTask name="q-01">
              <strong>Hälfte des Lebens</strong>
            </SyllablesTask>
            <Stack space={3}>
              <SyllablesTask name="q-02">
                Mit gelben Birnen hänget
              </SyllablesTask>
              <SyllablesTask name="q-03">
                Und voll mit wilden Rosen
              </SyllablesTask>
              <SyllablesTask name="q-04">Das Land in den See,</SyllablesTask>
              <SyllablesTask name="q-05">Ihr holden Schwäne,</SyllablesTask>
              <SyllablesTask name="q-06">Und trunken von Küssen</SyllablesTask>
              <SyllablesTask name="q-07">Tunkt ihr das Haupt</SyllablesTask>
              <SyllablesTask name="q-08">
                Ins heilignüchterne Wasser.
              </SyllablesTask>
            </Stack>
            <Stack space={3}>
              <SyllablesTask name="q-09">
                Weh mir, wo nehm’ ich, wenn
              </SyllablesTask>
              <SyllablesTask name="q-10">
                Es Winter ist, die Blumen, und wo
              </SyllablesTask>
              <SyllablesTask name="q-11">Den Sonnenschein,</SyllablesTask>
              <SyllablesTask name="q-12">Und Schatten der Erde?</SyllablesTask>
              <SyllablesTask name="q-13">Die Mauern stehn</SyllablesTask>
              <SyllablesTask name="q-14">
                Sprachlos und kalt, im Winde
              </SyllablesTask>
              <SyllablesTask name="q-15">Klirren die Fahnen.</SyllablesTask>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
